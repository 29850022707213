import React, {useState, useEffect} from "react";

import { Main } from "./Main";

import { useStore } from "../../context/state";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import "./SideMenu.module.scss";

export const SideMenu = (props) => {
  const { isOpen } = props;
  const [deliveryList, setDeliveryList] = useState([]);
  const [storeList, setStoreList] = useState([]);

  const { ecommerceStoreId, isMobile, apiPath, dispatch } = useStore();

  const onClose = () => dispatch("sideMenu", { isSideMenuOpen: false });

  const goToPage = (link) => {
    dispatch(link);
    onClose();
  };

  const requestDeliveryStore = (parentId) => {
      dataSourceV2(`ecommerce_store_delivery_option_pickup_list?ecommerce_store_delivery_option_id=${parentId}`, {url: apiPath})
          .then(v=> {
              if (!v.message && v.item_list && v.item_list.length) {
                  setStoreList(v.item_list);
              }
          });
  };

  const requestDelivery = () => {
      dataSourceV2(`ecommerce_store_delivery_option_list?ecommerce_store_id=${ecommerceStoreId}`, {url: apiPath})
          .then(v=> {
              if (!v.message && v.item_list && v.item_list.length) {
                  setDeliveryList(v.item_list.filter(v => !v.is_hidden));

                  v.item_list.map(item => {
                      if (item.type === "pick_up") {
                          requestDeliveryStore(item.ecommerce_store_delivery_option_id);
                      }
                  });
              }
          });
  };

  useEffect(() => {
      if (isMobile) requestDelivery();
  }, []);

  return (
    <SwipeableDrawer
      open={isOpen}
      onOpen={() => dispatch("sideMenu", { isSideMenuOpen: true })}
      onClose={onClose}
    >
      <div className="u-flexColumn">
        <Main {...props} onClose={onClose} deliveryList={deliveryList} storeList={storeList} goToPage={goToPage} />
      </div>
    </SwipeableDrawer>
  );
};

export const sideMenu = {
  isSideMenuOpen: false,
};
