import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import { getDeliveryUpsaleText } from "../../utils";
import CountWidget from "./CountWidget";
import {calcPrice, renderNameVertical} from "../../utils";
import "./style.styl";

const mapping = {
    pick_up: "Store",
    delivery: "Delivery",
    event: "Event"
};

const getType = (item) => {
    if (!item) return "";
    if (item["ecommerce_store_delivery_option_pickup_id"]) {
        return "pick_up";
    } else if (item["ecommerce_store_delivery_option_rule_id"]) {
        return "delivery";
    } else if (item["ecommerce_store_delivery_option_event_id"]) {
        return "event";
    }
    return "";
};

export default function CartContent(props){
    const {items, type, deliveryItem, isMobile, isOpen, onClose, onContinue, drawerClassName, mediaPath, noImage, noWatermark, dispatch} = props;
    const total = items.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0).toFixed(2);
    const isCartEmpty = !items.length;

    return (
        <div className="u-sizeFullHeight u-flexColumn">
            <div className="cart-header u-flexCenter u-justifyContentSpaceBetween u-paddingHorizontal15">
                <div className="u-flexColumn u-paddingVertical15">
                    <DialogTitle className="u-padding0">Cart</DialogTitle>
                    {
                        Object.keys(deliveryItem || {}).length ?
                            <span className="u-fontSize10">{mapping[getType(deliveryItem)] || "Delivery"}: {(deliveryItem || {}).name}</span>
                            :
                            null
                    }
                </div>
                <IconButton className="u-marginRightNegative5" color="inherit" onClick={onClose}>
                    <Close/>
                 </IconButton>
            </div>
            <List className="cart-content u-paddingVertical10 u-borderTop1 u-borderColorLightest u-minHeight250">
                {
                    isCartEmpty ?
                        <div className="u-sizeFullHeight u-flexColumn u-justifyContentCenter u-alignItemsCenter">
                            <div className="u-paddingHorizontal50 u-paddingBottom20">
                                <img className="u-sizeFullWidth u-hide" src="/static/img/empty_cart_2.png"/>
                            </div>
                            <div className="u-textColorNormal u-textAlignCenter u-marginTop50">
                                Empty Cart
                            </div>
                        </div>
                        :
                        null
                }
                {
                    items.map((item, i) => {

                        const product = item.product || {};
                        const featureDict = item.featureDict || {};
                        const featureList = item.featureList || [];
                        const {price_fixed, discount_fixed, discount_percent} = calcPrice(product, featureList);
                        const price = discount_percent ? discount_fixed : price_fixed;
                        const path = product.images ? mediaPath + (product.images || "").split(",")[0] : (noImage || "https://assets.smallcase.com/images/smallcases/200/SCSB_0004.png");
                        const noBorder = (i === items.length - 1) ? " u-border0 " : " u-borderBottom1 u-borderColorLightest ";
                        return (
                            <ListItem key={i} className={"u-paddingVertical0 u-paddingRight0 " + noBorder}>
                                <div className="u-flexCenter u-sizeFullWidth u-paddingRight15">
                                    <div className="u-relative u-inlineBlock u-marginVertical4 u-verticalAlignTop u-marginRight15">
                                        <div className={"image-widget-item u-relative u-width80 u-height80"}
                                             style={{backgroundImage: `url(${path})`, backgroundSize: "contain"}}></div>
                                    </div>
                                    <div className="u-sizeFullWidth u-flexColumn">
                                        <div className="name u-fontSize12 u-textColorNormal u-marginBottom5 u-xs-fontSize12">{renderNameVertical(product, {featureList, features: []} )}</div>
                                        <div className="u-flexCenter u-justifyContentSpaceBetween u-xs-fontSize16">
                                            <div className={discount_percent ? "u-textColorRed" : ""}>$ {price}</div>
                                            <div className="u-flexCenter">
                                                <CountWidget itemIdValue={item.id} value={item.count || 0} dispatch={dispatch}/>
                                                <div className="u-flexCenter u-marginLeft5">
                                                    <IconButton className="u-padding2 u-opacity80" onClick={() => dispatch("cart", {id: item.id}, "remove_product")}>
                                                        <Delete className="u-fontSize15"/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        );
                    })
                }
            </List>
            {
                !isCartEmpty ?
                    <div className="cart-actions u-borderTop1 u-borderColorLightest">
                        <div className="u-paddingLeft15 u-flex u-justifyContentSpaceBetween u-xs-fontSize16 u-xs-flexColumn u-paddingRight15 u-paddingVertical15">
                            <div className="u-flexCenter">
                                <div>Total:</div>
                                <div className="u-marginLeft10">$ {total}</div>
                            </div>
                            <span className="u-fontSize10 u-textColorNormal u-xs-marginTop5">
                                {getDeliveryUpsaleText((deliveryItem || {}).delivery_cost_json, parseFloat(total))}
                            </span>
                        </div>
                        <div className="u-flexCenter u-xs-flexColumn u-justifyContentCenter u-paddingBottom20 u-paddingHorizontal15">
                            <Button className="button-grey u-height48 u-marginRight10 u-xs-marginRight0 u-xs-sizeFullWidth"
                                    onClick={() => {(onContinue ? onContinue() : null);onClose();}}>
                                Continue Shopping
                            </Button>

                            <Button className="button-secondary u-height48 u-marginLeft10 u-xs-marginLeft0 u-xs-sizeFullWidth u-xs-marginTop15"
                                    link-meta="/checkout"
                                    onClick={() => {onClose();dispatch("/checkout");}}>
                                Checkout <ChevronRight/>
                            </Button>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    );
}
