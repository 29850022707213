import React from "react";
import Link from "next/link";

import CompanyLogo from "../../images/img/newlogo1.png";

export const Logo = (props) => {
  const { size } = props;
  return (
    <>
      <Link href="/">
        <a aria-label="Go to homepage">
          <span className="u-flexCenter u-cursorPointer u-fontWeightBold u-fontSize18 u-textColorNormal">
            <img
              height={size === "large" ? "150" : "110"}
              className=""
              alt="Piroshky Bakery Company Logo"
              src={CompanyLogo}
            />
          </span>
        </a>
      </Link>
    </>
  );
};
