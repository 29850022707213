import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import "./style.styl";


export default function CountWidget(props){
    const {value} = props;
    const [localValue, setLocalValue] = useState(value || 0);
    const [isLocal, setIsLocal] = useState(false);

    const onChange = (value) => {
        props.dispatch("cart", {id: props.itemIdValue, value: parseFloat(value)}, "change_count");
    };

    const onChangeButton = (add) => {
        let count = parseFloat(value || 0);
        if (add) {
            count += 1;
        } else {
            count += -1;
        }
        onChange(count);
    };

    return (
        <div className="u-flexCenter ">
            <IconButton className="u-padding2" disabled={value <= 1} onClick={() => onChangeButton(false)}>
                <Remove className="u-fontSize15"/>
            </IconButton>
            <input  className="u-marginHorizontal2 u-border0 u-textAlignCenter u-backgroundColorTransparent"
                    style={{width: 40}}
                    type="number"
                    onBlur={(e) => {setIsLocal(false); (e.target.value ? onChange(parseFloat(e.target.value)) : null);}}
                    onChange={(e) => {setIsLocal(true); setLocalValue(e.target.value ? parseFloat(e.target.value) : null);}}
                    value={isLocal ? (localValue || null) : value}/>
            <IconButton className="u-padding2" onClick={() => onChangeButton(true)}>
                <Add className="u-fontSize15"/>
            </IconButton>
        </div>
    );
}
