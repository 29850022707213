import React, { useState, useEffect, useRef } from "react";
import CartContent from "./CartContent";
import Drawer from "@material-ui/core/Drawer";
import Dialog from "@material-ui/core/Dialog";
import { getFromLS, saveToLS } from "commons-ui/core/utils/index";
import CRUD from "commons-ui/core/CRUD";
import { calcPrice } from "../utils";
import { requestProductListTemplate } from "./Requests";
import "./style.styl";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const getType = (item) => {
    if (!item) return "";
    if (item.ecommerce_store_delivery_option_event_id) {
        return "event";
    } else if (item.ecommerce_store_delivery_option_pickup_id) {
        return "pick_up";
    } else if (item.ecommerce_store_delivery_option_rule_id) {
        return "delivery";
    }
    return "";
};
const getId = (item) => {
    if (!item) return "";
    return item.ecommerce_store_delivery_option_event_id || item.ecommerce_store_delivery_option_pickup_id || item.ecommerce_store_delivery_option_rule_id || "";
};

export default function Cart(props) {
  const {
    items,
    type,
    update,
    isOpen,
    width,
    isMobile,
    locationId,
    drawerClassName,
    drawerClasses,
    ecommerceStoreGroupId,
    ecommerceStoreId,
    deliveryItem,
    apiPath,
    dispatch,
  } = props;
  const onClose = () => props.dispatch("cart", { isOpen: false }, "close_cart");

  const getFilterQuery = () => {
    const res = [];
    res.push({
      terms: { "search_data.ecommerce_product_id": items.map((v) => v.id) },
    });
    return res;
  };

  const setItemData = (items) => {
    const result = items.itemList
      .map((item, i) => {
        const cartItems = props.items.filter(
          (v) => v.id === item.ecommerce_product_id
        );
        if (cartItems.length) {
          const { final_price } = calcPrice(item);
          return { ...cartItems[0], product: item, price: final_price };
        }
        return null;
      })
      .filter((v) => !!v);
    props.dispatch("cart", { items: result });
  };

  const request = requestProductListTemplate({
    setItemData,
    getFilterQuery,
    path: "ecommerce_product_view_list",
    itemId: "ecommerce_product_id",
    apiPath,
    ecommerceStoreGroupId,
    ecommerceStoreId,
    searchInStoreGroup: true,
    noFacet: true,
    hiddenDirect: true,
    postprocess: (deliveryItem || {}).locationId || locationId || "default"
  });

  console.log(deliveryItem, locationId);

  useEffect(() => {
      if (items && items.length > 0) {
        request({});
      }
  }, []);


  return (
    <Dialog
      fullScreen={isMobile}
      onClose={onClose}
      open={isOpen}
      maxWidth="sm"
      fullWidth
      className="tree-widget1 cart-dialog"
      scroll="paper"
    >
      <CartContent {...props} onClose={onClose} />
    </Dialog>
  );

  return null;
}

export const cartReducer = (state, payload, action) => {
  console.log(state, payload, action);
  if (action === "add_product" || action === "add_product_without_cart") {
    const ecommerceStoreId =
      payload.ecommerceStoreId || state.ecommerceStoreId || "";
    if (
      ecommerceStoreId &&
      state.ecommerceStoreId &&
      state.ecommerceStoreId !== ecommerceStoreId
    ) {
      state.items = [];
    }
    if (payload.reset) {
        state.items = [];
    }

    const deliveryItem = payload.deliveryItem || state.deliveryItem || {};
    const deliveryType = deliveryItem.delivery_type || getType(deliveryItem);
    const locationId = getId(deliveryItem);
    if (Object.keys(deliveryItem).length) {
        deliveryItem.delivery_type = deliveryType;
        deliveryItem.location_id = locationId;
    }

    let items = undefined;

    const products = payload.products || [payload];
    products.forEach((productPayload, i) => {
      const featureList = productPayload.featureList || [];
      const product = productPayload.product || {};
      let itemDb = CRUD(
        state.items,
        "id",
        product.ecommerce_product_id,
        undefined,
        "GET"
      );
      const { final_price } = calcPrice(product, featureList);
      const qty = (itemDb.count || 0) + (productPayload.count || productPayload.value || 1);
      const item = {
        product,
        count: qty,
        qty,
        id: product.ecommerce_product_id,
        price: final_price,
        featureDict: productPayload.featureDict || {},
        featureList: featureList,
      };
      items = CRUD(
        items || state.items,
        "id",
        item.id,
        item,
        itemDb.id ? "UPDATE" : "CREATE"
      );
    });

    let isOpen = action === "add_product_without_cart" ? false : true;
    saveToLS("cart", { ...state, items, ecommerceStoreId, deliveryItem, isOpen });
    return { items, ecommerceStoreId, deliveryItem, isOpen };
  } else if (action === "remove_product") {
    let items = CRUD(state.items, "id", payload.id, undefined, "DELETE");
    const deliveryItem = (items.length === 0 ? {} : state.deliveryItem);
    saveToLS("cart", { ...state, items, deliveryItem});
    return { items, deliveryItem };
  } else if (action === "change_count") {
    const items = CRUD(
      state.items,
      "id",
      payload.id,
      undefined,
      "UPDATE_PARTIAL",
      { key: "count", value: payload.value || 0 }
    );
    //items = CRUD(items, "id", payload.id, undefined, "UPDATE_PARTIAL", {key: "qty", value: payload.count || 0});
    saveToLS("cart", { ...state, items });
    return { items };
  } else if (action === "update_final_price") {
    const id = payload.id
    let finalPrice = payload.final_price
    const free = payload.free
    if (free <= 0 || !free) {
      finalPrice = -1
    }
    const newItems = state.items.map(v => {
      if (v.id === id) {
        const product = v.product || {}
        if (finalPrice < 0) {
          delete v.final_price
          delete product.final_price
          delete v.free
          delete product.free
        } else {
          v.final_price = finalPrice;
          product.final_price = finalPrice;
          if (free) {
            v.free = free;
            product.free = free;
          }
        }
      }
      return v
    })
    //items = CRUD(items, "id", payload.id, undefined, "UPDATE_PARTIAL", {key: "qty", value: payload.count || 0});
    saveToLS("cart", { ...state, items: newItems });
    return { items: newItems };
  } else if (action === "remove_final_price") {
    const newItems = state.items.map(v => {
      const product = v.product || {}
      delete v.final_price
      delete v.free
      delete product.free
      delete product.final_price
      return v
    })
    //items = CRUD(items, "id", payload.id, undefined, "UPDATE_PARTIAL", {key: "qty", value: payload.count || 0});
    saveToLS("cart", { ...state, items: newItems });
    return { items: newItems };
  } else if (action === "close_cart" || action === "open_cart") {
    saveToLS("cart", { ...state, ...payload });
    return payload;
  } else if (action === "clear_cart") {
    payload = { items: [], deliveryItem: {} };
    saveToLS("cart", { ...state, ...payload });
    return payload;
  }
};

const initCart = () => {
    const cart = getFromLS("cart") || {
      newItem: {},
      isOpen: false,
      isDialogOpen: false,
      type: "drawer_flat",
      items: [],
      update: false,
      ecommerceStoreId: "",
      deliveryItem: {}
    };
    cart.deliveryItem = cart.deliveryItem || {};
    return cart;
};

export const cart = initCart();
